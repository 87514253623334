<template>
  <div>
    <a-space class="w-full" :size="20" direction="vertical">
      <div class="p-20 bg-white" v-for="(data, index) in origin" :key="index">
        <Case :dataSource="data" @update="update" />
      </div>
    </a-space>
  </div>
</template>

<script>
import Case from "./case.vue";
import { getCases } from "@/api";
import { getProCaseDetail } from "@/api/case.js";
export default {
  components: {
    Case,
  },
  data() {
    return {
      origin: [],
      status: {},
    };
  },
  created() {
    const id = this.$route.params.id;
    this.id = id;
    this.update();
  },
  methods: {
    update() {
      if (this.id) {
        this.getProCaseDetail(this.id);
      } else {
        this.getCases();
      }
    },
    getCases() {
      getCases().then(({ data }) => {
        const temp = data.rows || [];
        this.origin = temp;
        this.origin.push({});
      });
    },
    getProCaseDetail(id) {
      getProCaseDetail(id).then(({ data }) => {
        this.origin = [data];
      });
    },
  },
};
</script>